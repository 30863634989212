import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { toast } from '../../theme';

const apiURI =
  process.env.REACT_APP_LOCAL_SERVER === 'true'
    ? process.env.REACT_APP_API_LOCAL_SERVER_URI
    : process.env.REACT_APP_API_URI;

const errorHandlerLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // eslint-disable-next-line no-console
    graphQLErrors.forEach((e) => console.error(e.message, e));
  }
  if (networkError) {
    // eslint-disable-next-line no-console
    console.error(`[Network error]: ${networkError}`);
  }
});

const graphqlLink = createHttpLink({
  uri: apiURI,
  credentials: 'include'
});

const Errors = {
  failedToUpload: 'Falla en la subida del documento.',
  failedToFetch: 'Falla de conexión para carga de datos.',
  failedToMutate: 'Falla de conexión para modificación de datos.',
  loginError: 'Verifique su clave y nombre de usuario.',
  unauthenticated: 'Debe iniciar su sesión.',
  unauthorized: 'No esta autorizado para realizar esta acción.',
  paymentIsNotRevertible: 'El pago no se puede revertir.',
  creditIsNotApproved: 'El crédito no ha sido aprobado.',
  maxLimitReached: 'El pago excede al correspondiente de la cuota',
  notEnoughBalance:
    'El saldo de la cryptomoneda en colateral es insuficiente al solicitado'
};

const errorsHandler = (errors) => {
  const error = errors[0] || errors;
  let errorMessage = '';
  if (error.message) {
    errorMessage = Errors[error.message]
      ? Errors[error.message]
      : 'Ocurrio un error desconocido.';
  } else {
    errorMessage = 'Ocurrio un error desconocido.';
  }
  toast.danger('ERROR', errorMessage);
};

export { errorHandlerLink, graphqlLink, errorsHandler };
