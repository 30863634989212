import React from 'react';
import PropTypes from 'prop-types';
import { withApollo } from '@apollo/client/react/hoc';
import { errorsHandler } from '../../graphql/config';
// import { toast } from '../../theme';
import createTokenProvider from '../token';
import { AuthContext, defaultUserData } from './auth-context';
import { USER_BY_TOKEN } from './requests';

class AuthProvider extends React.Component {
  constructor() {
    super();
    this.state = {
      userData: defaultUserData,
      defaultUser: defaultUserData,
      setUser: this.setUser,
      clearUser: this.clearUser,
      useAuth: this.useAuth,
      getUserData: this.getUserData,
      logout: this.logout
    };
  }

  componentDidMount() {
    const user = this.getUserData();
    this.setState({ userData: user });
    setInterval(() => this.getUserData(), 30000);
  }

  setUser = (user) => {
    this.setState({ userData: user });
  };

  clearUser = () => {
    this.setState({ userData: defaultUserData });
  };

  getUserByToken = async () => {
    const { defaultUser } = this.state;
    let user = defaultUser;
    try {
      const { client } = this.props;
      const { errors, data } = await client.query({
        query: USER_BY_TOKEN
      });
      if (errors) {
        errorsHandler(errors);
      } else {
        user = data.userByToken;
        this.setState({ userData: user }, () =>
          this.setState({ isLoadingUser: false })
        );
      }
    } catch (e) {
      errorsHandler([{ message: 'failedToFetch' }]);
    }
    return user;
  };

  getUserData = async () => {
    const { userData, defaultUser } = this.state;
    let user = defaultUser;
    const tp = createTokenProvider();
    if (tp.isLoggedIn()) {
      if (userData !== defaultUser) {
        user = userData;
      } else {
        user = this.getUserByToken();
      }
    } else if (userData !== defaultUser) {
      errorsHandler([{ message: 'unauthenticated' }]);
      this.setState({ userData: defaultUser });
    }
    return user;
  };

  logout = () => {
    const tp = createTokenProvider();
    tp.clearToken();
    this.clearUser();
  };

  useAuth = () => {
    const tp = createTokenProvider();
    return tp.isLoggedIn();
  };

  render() {
    const { children } = this.props;
    return (
      <AuthContext.Provider value={{ authContext: { ...this.state } }}>
        {children}
      </AuthContext.Provider>
    );
  }
}

AuthProvider.propTypes = {
  client: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

export default withApollo(AuthProvider);
