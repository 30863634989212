import styled from 'styled-components';

const Box = styled.div`
    ${props => props.width && `width: ${props.width};`}
    ${props => props.height && `height: ${props.height};`}
    ${props => props.display && `display: ${props.display};`}
    ${props => props.padding && `padding: ${props.padding};`} 
    ${props => props.justifyContent && `justify-content: ${props.justifyContent};`}
    ${props => props.position && `position: ${props.position};`}
    ${props => props.flexDirection && `flex-direction: ${props.flexDirection};`}
    ${props => props.alignItems && `align-items: ${props.alignItems};`}
    ${props => props.flex && `flex: ${props.flex};`}
    ${props => props.marginTop && `margin-top: ${props.marginTop};`}
    ${props => props.ml && `margin-left: ${props.ml};`}
    ${props => props.my && `margin-top: ${props.my}; margin-bottom: ${props.my};`}
    ${props => props.clickable && `cursor: pointer;`}
`;

export default Box;