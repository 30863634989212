import { css } from 'styled-components';

// Special thanks to https://github.com/AndrewBarba

const fontFaces = css`
  /** Cairo */
  @font-face {
    font-family: 'Cairo-Regular';
    src: url('/fonts/Cairo-Regular/Cairo-Regular.eot');
    src: local('Cairo-Regular'),
        url('/fonts/Cairo-Regular/Cairo-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Cairo-Regular/Cairo-Regular.woff2') format('woff2'),
        url('/fonts/Cairo-Regular/Cairo-Regular.woff') format('woff'),
        url('/fonts/Cairo-Regular/Cairo-Regular.ttf') format('truetype'),
        url('/fonts/Cairo-Regular/Cairo-Regular.svg#Cairo-Regular') format('svg');

    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Cairo-Bold';
    src: url('/fonts/Cairo-Bold/Cairo-Bold.eot');
    src: local('Cairo-Bold'),
        url('/fonts/Cairo-Bold/Cairo-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Cairo-Bold/Cairo-Bold.woff2') format('woff2'),
        url('/fonts/Cairo-Bold/Cairo-Bold.woff') format('woff'),
        url('/fonts/Cairo-Bold/Cairo-Bold.ttf') format('truetype'),
        url('/fonts/Cairo-Bold/Cairo-Bold.svg#Cairo-Bold') format('svg');

    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
`;

export default fontFaces;
