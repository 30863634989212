import styled, { keyframes } from 'styled-components';

const GeneralContainer = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100vw;
  background: ${(props) => props.theme.colors.light};
`;

const NavBarContainer = styled.div`
  top: 0;
  display: flex;
  width: 100vw;
  height: 70px;
  padding-right: 70px;
  background-color: ${(props) => props.theme.colors.default};
  color: ${(props) => props.theme.colors.lighter};
  justify-content: flex-end;
  align-items: center;
`;

const MainFooterContainer = styled.div`
  z-index: 100;
  width: 100vw;
  margin: 2em 0 0 0;
  padding: 2em 5em 20px 10em;
  display: flex;
  justify-content: space-between;
  align-items: space-between;
  background-color: ${(props) => props.theme.colors.default};
  ${({ theme }) => theme.media.tablet`
  flex-direction: column;
  *:nth-child(n) {
    margin: 1vw 0 2vw 0;
  }
  `}
`;

const LeftFooterContainer = styled.div`
  z-index: 100;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${(props) => props.theme.colors.white};
  ${({ theme }) => theme.media.tablet`
  justify-content: center;
  `}
`;

const CenterFooterContainer = styled.div`
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RightFooterContainer = styled.div`
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  *:nth-child(n) {
    font-size: 16px;
    font-family: Cairo-Regular;
  }
  ${({ theme }) => theme.media.tablet`
  justify-content: center;
  *:nth-child(n) {
    font-size: 12px;
  }`}
`;

const LateralMenuContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 60px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: ${({ theme }) => theme.menuRadius}
    ${({ theme }) => theme.menuRadius} 0px 0px;
  background: ${(props) => props.theme.colors.lighter};
`;

const MenuContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
`;

const ScrollContainer = styled.div`
  display: flex;
  transition-duration: 0.5s;
  position: absolute;
  top: 0px;
  left: 0px;
  flex-direction: column;
  margin: 7px 0px 7px 0px;
  width: 7px;
  height: 50px;
  flex-direction: column;
  border-radius: 0px ${({ theme }) => theme.radius}
    ${({ theme }) => theme.radius} 0px;
  background: ${({ theme }) => theme.colors.gradientRLVertical};
  filter: drop-shadow(0px 0px 4px ${({ theme }) => theme.colors.gradientEnd});
  transform: translateY(calc(60px * ${({ selected }) => selected}));
`;

const glowAnimation = keyframes`
	0% {
		box-shadow: 0 0 #ff4713;
    background-color: #ff4713;
	}
	100% {
		box-shadow: 0 0 30px 10px transparent;
	}
`;

const MenuElementContainer = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ select, theme }) =>
    select ? theme.colors.primary : theme.colors.default};
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.primary};
    *:nth-child(1) {
      animation: ${glowAnimation} 1.5s ease;
    }
  }
  *:nth-child(1) {
    font-size: 20px;
  }
`;

const UpMenuContainer = styled.div`
  position: absolute;
  left: 90px;
  top: 15px;
  width: 190px;
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.colors.default};
  overflow-x: hidden;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 1em 0 2.5em 0;
  *:nth-child(1) {
    font-size: 20px;
  }
`;

const AdminAvatarContainer = styled.div`
  ${({ fullSize, size }) =>
    fullSize
      ? `
width: 8vw;
height: 8vw;
min-width:  ${size};
min-height:  ${size};
  `
      : `
  width: ${size};
  height: ${size};
  `};
  ${({ imgFile }) => (imgFile ? `` : `padding: 0.8px;`)}
  border-radius: 50%;
  outline-radius: 50%;
  overflow: hidden;
  background: ${({ avatarBg, theme }) => theme.colors[avatarBg]};
  font-size: 18px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  *:nth-child(n) {
    ${({ fullSize, size }) =>
      fullSize
        ? `
font-size: 3.5vw;
  `
        : `
      font-size: ${size}px;
  `};
    font-family: Cairo-Bold;
    color: ${({ color, theme }) => theme.colors[color]};
  }
  ${({ borderWidth, borderColor, theme }) =>
    `box-shadow: 0 0 0 ${borderWidth}  ${theme.colors[borderColor]};`}
`;

const ProfilePictureImage = styled.img`
  height: 100%;
  min-width: 100%;
`;

export {
  GeneralContainer,
  NavBarContainer,
  MainFooterContainer,
  LeftFooterContainer,
  CenterFooterContainer,
  RightFooterContainer,
  LateralMenuContainer,
  UpMenuContainer,
  MenuElementContainer,
  LogoContainer,
  MenuContainer,
  ScrollContainer,
  AdminAvatarContainer,
  ProfilePictureImage
};
