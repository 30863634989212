import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { BlockdemyUIProvider } from './providers/theme';
import AuthProvider from './providers/auth';
import client from './graphql';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <AuthProvider>
        <BlockdemyUIProvider>
          <Router basename="/">
            <App />
          </Router>
        </BlockdemyUIProvider>
      </AuthProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
