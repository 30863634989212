import React from 'react';
import { AuthContext } from '../auth-context';

const withAuthContext = (Component) => (props) => (
  <AuthContext.Consumer>
    {(context) => <Component {...props} {...context} />}
  </AuthContext.Consumer>
);

export default withAuthContext;
