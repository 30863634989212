import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { errorHandlerLink, graphqlLink } from './config';

const link = ApolloLink.from([errorHandlerLink, graphqlLink]);
const client = new ApolloClient({
  link,
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all'
    },
    mutate: {
      errorPolicy: 'all'
    }
  },
  cache: new InMemoryCache()
});

export default client;
