import { createGlobalStyle } from 'styled-components';
import fontFaces from './font-faces';

const GlobalStyle = createGlobalStyle`

${fontFaces}

  * {
    box-sizing: border-box;
    font-family: Cairo-Regular;
    font-weight:  400;
    line-height: 1.0;
    font-size: 16px;
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    background: ${({ theme }) => theme.colors.background};
  }

  html,
  body {
    min-height: 100%;
    min-width: 100%;
  }

  .multi-select {
    --rmsc-main: ${({ theme }) => theme.colors.veryLightGrey} !important;
    --rmsc-hover: ${({ theme }) => theme.colors.lightDark} !important;
    --rmsc-selected: ${({ theme }) => theme.colors.primary} !important;
    --rmsc-border: ${({ theme }) => theme.colors.veryLightGrey} !important;
    --rmsc-gray: ${({ theme }) => theme.colors.veryLightGrey} !important;
    --rmsc-bg: ${({ theme }) => theme.colors.lighter} !important;
    --rmsc-p: 4px !important; /* Spacing */
    --rmsc-radius: ${({ theme }) => theme.menuRadius}!important; /* Radius */
    --rmsc-h: 33px !important; /* Height */
  } 

  tr:hover { 
    background: ${({ theme }) => theme.colors.greenSoft200}; 
 }

 td  a { 
    display: block; 
    margin: -6px -16px;
 }

  body a {
    display: contents;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }

  #tooltips {
    position: absolute;
    top: 0;
    left: 0;
    pre {
      font-family: 'Montserrat';
      font-weight:  400;
      font-size: 12px;
    }
  }

  #toast {
    z-index: 3500;
    position: fixed;
    top: 65px;
    right: 0;
    padding: 4px;
    width: 320px;
    box-sizing: border-box;
    animation: all 0.8 ease;
    font-family: 'Montserrat';
    font-weight:  400;
    font-size: 12px;
  }

  @media (max-width: 36em) {
    #toast {
      width: 100%;
    }
  }
`;

export default GlobalStyle;
