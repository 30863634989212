import { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import { theme } from '../../theme';

const Login = lazy(() => import(/* webpackChunkName: "Login" */ './login'));

const DeleteAccount = lazy(() =>
  import(/* webpackChunkName: "Credits" */ './deleteAccount')
);

TopBarProgress.config({
  barColors: {
    0: theme.colors.gradientStart,
    '1.0': theme.colors.gradientEnd
  },
  shadowBlur: 5
});

const Auth = () => (
  <Suspense fallback={<></>}>
    <Suspense fallback={<TopBarProgress />}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/delete-account" component={DeleteAccount} />
        <Redirect to="/login" />
      </Switch>
    </Suspense>
  </Suspense>
);

export default Auth;
