import { Suspense, lazy } from 'react';
// import PropTypes from 'prop-types';

import { Switch, Route, Redirect } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import MainLayout from '../../layouts/main';
import { theme } from '../../theme';

const Dashboard = lazy(() =>
  import(/* webpackChunkName: "Dashboard" */ './dashboard')
);
const Products = lazy(() =>
  import(/* webpackChunkName: "Products" */ './products')
);

const AddProduct = lazy(() =>
  import(/* webpackChunkName: "Add Products" */ './products/add-product')
);
const DeleteAccount = lazy(() =>
  import(/* webpackChunkName: "Add Products" */ '../auth/deleteAccount')
);
const Users = lazy(() => import(/* webpackChunkName: "Users" */ './users'));

const User = lazy(() => import(/* webpackChunkName: "User" */ './users/user'));

const Credits = lazy(() =>
  import(/* webpackChunkName: "Credits" */ './credits')
);

const Credit = lazy(() =>
  import(/* webpackChunkName: "User" */ './credits/credit')
);

TopBarProgress.config({
  barColors: {
    0: theme.colors.gradientStart,
    '1.0': theme.colors.gradientEnd
  },
  shadowBlur: 5
});

const Home = () => (
  <MainLayout>
    <Suspense fallback={<TopBarProgress />}>
      <Switch>
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/products" component={Products} />
        <Route exact path="/users" component={Users} />
        <Route exact path="/users/:userId" component={User} />
        <Route exact path="/credits" component={Credits} />
        <Route exact path="/credits/:creditId" component={Credit} />
        <Route exact path="/products/add-product" component={AddProduct} />
        <Route exact path="/delete-account" component={DeleteAccount} />
        <Redirect to="/dashboard" />
      </Switch>
    </Suspense>
  </MainLayout>
);

export default Home;
