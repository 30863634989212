import React from 'react';

const defaultUserData = {
  id: null,
  firstName: null,
  lastName: null,
  profileImg: null,
  email: null,
  isGlobalAdmin: null
};

const AuthContext = React.createContext({
  userData: defaultUserData,
  defaultUser: defaultUserData,
  setUser: () => {},
  clearUser: () => {},
  useAuth: () => {},
  logout: () => {}
});

export { AuthContext, defaultUserData };
