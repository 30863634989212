import { gql } from '@apollo/client';

const USER_BY_TOKEN = gql`
  query userByToken {
    userByToken {
      id
      firstName
      lastName
      email
      isGlobalAdmin
    }
  }
`;

export { USER_BY_TOKEN };
