import styled from 'styled-components';

export const Typography = styled.p`
    font-size: ${(props) => props.theme.fontSize[props.variant || 'paragraph']};
    margin: 0;
    ${(props) => props.color && `color: ${props.theme.colors[props.color]};`}
    ${(props) => props.fontSize && `font-size: ${props.fontSize};`}
    ${(props) => props.fontFamily && `font-family: ${props.fontFamily};`}
    ${(props) => props.mt && `margin-top: ${props.mt};`}
    ${(props) => props.mb && `margin-bottom: ${props.mb};`}
    ${(props) => props.ml && `margin-left: ${props.ml};`}
    ${(props) => props.mr && `margin-right: ${props.mr};`}
    ${(props) =>
        props.variant === 'muted' && `color: ${props.theme.colors.lightGrey};`}
    ${(props) =>
        props.variant && `font-size: ${props.theme.fontSize[props.variant]};`}
`;

