import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withApollo } from '@apollo/client/react/hoc';
import Loader from 'blockdemy-ui/loader';
import { FiLogOut } from 'react-icons/fi';
import SimpleLineIcon from 'react-simple-line-icons';
import { errorsHandler } from '../../graphql/config';
import withAuthContext from '../../providers/auth/with-auth-context';
import { theme } from '../../providers/theme';
import {
  GeneralContainer,
  NavBarContainer,
  MainFooterContainer,
  LeftFooterContainer,
  RightFooterContainer,
  LateralMenuContainer,
  MenuElementContainer,
  UpMenuContainer,
  MenuContainer,
  ScrollContainer,
  AdminAvatarContainer,
  ProfilePictureImage
} from './elements';
import { LOG_OUT } from './requests';
import { Typography } from '../../elements/typography';
import Box from '../../elements/box';

const LPhantNameIcon = `${window.location.origin}/logo-white.svg`;

const MenuContext = React.createContext({
  changeMenu: () => {}
});

const MainLayout = ({ children, authContext, client }) => {
  const { setUser, defaultUser, userData } = authContext;
  const [selectedPage, setSelectedPage] = useState([true]);

  const changeMenu = (index) => {
    const tempState = [];
    tempState[index] = true;
    setSelectedPage(tempState);
  };

  const logout = async () => {
    try {
      const { errors } = await client.query({
        query: LOG_OUT,
        skip: typeof userData.id === 'undefined'
      });
      if (errors) {
        errorsHandler(errors);
      }
      setUser(defaultUser);
    } catch (e) {
      errorsHandler([{ message: 'failedToFetch' }]);
    }
  };

  return (
    <GeneralContainer hue="50">
      <NavBarContainer>
        <AdminAvatarContainer
          key="admin_avatar"
          imgFile={userData.profileImg}
          size="38px"
          avatarBg="primary"
          color="lighter"
        >
          {userData.profileImg ? (
            <ProfilePictureImage src={userData.profileImg} />
          ) : userData.id ? (
            <Typography>
              {userData.firstName?.charAt(0) || '-'}
              {userData.lastName?.charAt(0) || '-'}
            </Typography>
          ) : (
            <Loader size="16px" />
          )}
        </AdminAvatarContainer>

        <Box
          display="flex"
          justifyContent="center"
          clickable
          ml="10px"
          onClick={logout}
        >
          <FiLogOut color={theme.colors.lighter} size="1.5rem" />
        </Box>
      </NavBarContainer>
      <Box ml="60px" padding="0px 5vw 0px 5vw">
        <MenuContext.Provider
          value={{ changeMenu: (index) => changeMenu(index) }}
        >
          {children}
        </MenuContext.Provider>
      </Box>
      <MainFooterContainer>
        <LeftFooterContainer>
          {/* <a
            rel="noopener noreferrer"
            htmlFor="twitter"
            href="https://twitter.com/reactjs"
            target="_blank"
          >
            <Box clickable marginRight="4vw">
              <FontAwesomeIcon icon={faTwitter} />
            </Box>
          </a>
          <a
            rel="noopener noreferrer"
            htmlFor="facebook"
            href="https://www.facebook.com/"
            target="_blank"
          >
            <Box clickable marginRight="4vw">
              <FontAwesomeIcon icon={faFacebook} />
            </Box>
          </a>
          <a
            rel="noopener noreferrer"
            htmlFor="linkedin"
            href="https://www.linkedin.com/"
            target="_blank"
          >
            <Box clickable>
              <FontAwesomeIcon icon={faLinkedin} />
            </Box>
          </a> */}
        </LeftFooterContainer>

        <RightFooterContainer>
          <Typography color="white">
            © L-Phant 2021. Todos los derechos reservados.
          </Typography>
        </RightFooterContainer>
      </MainFooterContainer>

      <LateralMenuContainer>
        <MenuContainer>
          <ScrollContainer selected={selectedPage.indexOf(true)} />

          <Link to="/dashboard">
            <MenuElementContainer
              onClick={() => changeMenu(0)}
              select={selectedPage[0]}
            >
              <SimpleLineIcon name="home" />
            </MenuElementContainer>
          </Link>
          <Link to="/users">
            <MenuElementContainer
              onClick={() => changeMenu(1)}
              select={selectedPage[1]}
            >
              <SimpleLineIcon name="people" />
            </MenuElementContainer>
          </Link>
          <Link to="/credits">
            <MenuElementContainer
              onClick={() => changeMenu(2)}
              select={selectedPage[2]}
            >
              <SimpleLineIcon name="chart" />
            </MenuElementContainer>
          </Link>
          <Link to="/products">
            <MenuElementContainer
              onClick={() => changeMenu(3)}
              select={selectedPage[3]}
            >
              <SimpleLineIcon name="layers" />
            </MenuElementContainer>
          </Link>
        </MenuContainer>
      </LateralMenuContainer>
      <UpMenuContainer>
        <img src={LPhantNameIcon} alt="L-Phant_Name" />
      </UpMenuContainer>
    </GeneralContainer>
  );
};

MainLayout.propTypes = {
  authContext: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  client: PropTypes.object.isRequired
};

export { MenuContext };
export default withAuthContext(withApollo(MainLayout));
