import PropTypes from 'prop-types';
import withAuthContext from './providers/auth/with-auth-context';
import Auth from './views/auth';
import Home from './views/home';

function App({ authContext }) {
  const { useAuth } = authContext;
  if (!useAuth()) return <Auth />;
  return <Home />;
}

App.propTypes = {
  authContext: PropTypes.object.isRequired
};

export default withAuthContext(App);
