import { lighten, darken, transparentize, opacify } from 'polished';
import Toast from 'blockdemy-ui/toast';
import media from './media';

const { toast } = new Toast();

const brand = {
  primary: '#ff4713',
  secondary: '#773dbd',
  warning: '#ffbe4f',
  danger: '#ff695b',
  alert: '#FFD7AB',
  alert2: '#CCE3FF',
  success: '#3dcc79',
  default: '#0d1d19',
  gradientStart: '#ff4713',
  gradientEnd: '#773dbd',
  white: '#ffffff',
  green: '#3dcc79',
  greenLight: '#3DCC79',
  iconBackground: 'rgba(255,71,19,0.16)',
  

  background: '#F7F8FA',
  gradientWarningStart: '#FAD961',
  gradientWarningEnd: '#F76B1C',
  ghost: '#AAAEB3',
  transparent: 'rgba(255, 255, 255, 0)'
};



// $gray-color: #e5e8ef;

// $gray-1-color: #8d8d94;
// $gray-2-color: #babbc2;
// $gray-3-color: #e6e6eb;
// $gray-4-color: #f0f0f5;
// $dark-color: #0e1b3b;
// $dark-1-color: #010000;
// $dark-2-color: #13161d;
// $slider-color: #ecedef;


Object.keys(brand).forEach((color) => {
  brand[`${color}900`] = darken(0.28, brand[color]);
  brand[`${color}800`] = darken(0.21, brand[color]);
  brand[`${color}700`] = darken(0.14, brand[color]);
  brand[`${color}600`] = darken(0.07, brand[color]);
  brand[`${color}500`] = brand[color];
  brand[`${color}400`] = lighten(0.07, brand[color]);
  brand[`${color}300`] = lighten(0.14, brand[color]);
  brand[`${color}200`] = lighten(0.21, brand[color]);
  brand[`${color}100`] = lighten(0.28, brand[color]);

  brand[`${color}Soft`] = transparentize(0.8, brand[color]);
  brand[`${color}Soft900`] = transparentize(0.1, brand[color]);
  brand[`${color}Soft800`] = transparentize(0.2, brand[color]);
  brand[`${color}Soft700`] = transparentize(0.3, brand[color]);
  brand[`${color}Soft600`] = transparentize(0.4, brand[color]);
  brand[`${color}Soft500`] = transparentize(0.5, brand[color]);
  brand[`${color}Soft400`] = transparentize(0.6, brand[color]);
  brand[`${color}Soft300`] = transparentize(0.7, brand[color]);
  brand[`${color}Soft200`] = transparentize(0.8, brand[color]);
  brand[`${color}Soft100`] = transparentize(0.9, brand[color]);

  brand[`${color}Opac`] = opacify(0.5, brand[color]);
});

const grey = {
  darker: '#000000',
  dark: '#495065',
  semiDark: '#6F737A',
  lightDark: '#8E91A0',
  lightGrey: '#B2B5BF',
  veryLightGrey: '#D8D9DE',
  light: '#F8F9FD',
  veryLight: '#ECECEF',
  lighter: '#FFFFFF'
};

const sizes = [0, 500, 1000, 200];

const colors = {
  ...brand,
  ...grey,
  gradientRL: `linear-gradient(90deg, ${brand.gradientStart}, ${brand.gradientEnd});`,
  gradientLR: `linear-gradient(90deg, ${brand.gradientEnd}, ${brand.gradientStart});`,
  gradientRLVertical: `linear-gradient(0deg, ${brand.gradientStart}, ${brand.gradientEnd});`,
  gradientLRVertical: `linear-gradient(0deg, ${brand.gradientEnd}, ${brand.gradientStart});`,
  gradientWarning: `linear-gradient(90deg, ${brand.gradientWarningStart}, ${brand.gradientWarningEnd});`,
  gradientWarningVertical: `linear-gradient(0deg, ${brand.gradientWarningEnd}, ${brand.gradientWarningStart});`,
  gradientPrimary: `linear-gradient(90deg, ${brand.primary}, ${brand.primary});`
};

const theme = {
  sizes,
  media,
  font: 'CairoRegular, CairoBold, system-ui',
  shadow: '0 8px 20px 0 rgba(22, 50, 92, 0.1)',
  shadowCard: '0 4px 8px 0 rgba(22, 50, 92, 0.2)',
  shadowMenu: '60px -16px teal',
  shadowUserCard: '0 8px 32px 0 rgba(136, 136, 136, 0.25)',
  cardRadius: '22.5px',
  cardUserRadius: '8px',
  radius: '.3rem',
  menuRadius: '0.5em',
  maxWidth: '1170px',
  colors,
  space: [],
  fontSize: {
    h1: '2.5rem',
    h2: '2rem',
    h3: '1.75rem',
    h4: '1.5rem',
    h5: '1.25rem',
    h6: '1rem',
    d1: '3.3rem',
    d2: '2.75rem',
    d3: '2.1875rem',
    d4: '1.6275rem',
    heading: '1rem',
    headingTitle: '1.375rem',
    headingSection: '1.45rem',
    paragraph: '1rem',
    leadText: '1.25rem',
    muted: '0.85rem',
    f7: '0.42rem',
    f12: '0.73rem',
    f14: '0.85rem',
    f18: '1.125rem',
    f30: '1.865rem',
    tablet: '0.5rem'
  }
};

export { theme, toast };
